// custom typefaces
import "typeface-poppins"

// code theme
import "prismjs/themes/prism-okaidia.css"

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfLMOoZAAAAAC2l82jGCz5aZoFm_q_24TRNkY48">
            {element}
        </GoogleReCaptchaProvider>
    )
}